.bg-login-auth {
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url('/public/image-danone/factory.png');
}

.ReactQueryDevtools {
  display: none;
}

.loader {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 105;
    background-color: rgb(0 0 0 / 50%);
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.select-custom {
  background-image: url('../../../public/images/arrowcircledown.svg');
  background-repeat: no-repeat;
  background-position: center right;
  background-size: 20px;
}
.carousel.carousel-hygiene .carousel-control-next{
  right: -160px;
}

.scroll-style-4::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0.3, 0.3, 0.3, 0.3);
  background-color: #F5F5F5;
  /* background-color: #F5F5F5; */
}

.scroll-style-4::-webkit-scrollbar {
  width: 6px;
  background-color: #F5F5F5;
}

.scroll-style-4::-webkit-scrollbar-thumb {
  background-color: #242424;
  border: 1px solid #555555;
}
.scroll-style-4:hover::-webkit-scrollbar-thumb {
  background-color: #242424;
  border: 1px solid #555555;
}
.menu-column {
  max-height: calc(100vh - 70px);
  overflow: auto;
}
.menu-column::-webkit-scrollbar {
  display: none;
}